import React from "react";
import logo from '@/assets/images/footer/common/logo.png';
import serviceQR from '@/assets/images/footer/common/serviceQR.png';
import officialQR from '@/assets/images/footer/common/officialQR.png';
// import xcxPng from '@/assets/images/footer/common/xcx.png';
import policePng from '@/assets/images/footer/common/police.png';
import styles from './index.module.scss';

const MobileFooter = () => {
    return (
        <div className={styles.footer_box}>
            <div className={styles.scan_code}>
                <img src={logo} alt="" className={styles.logo} />
                <div className={styles.QR_code}>
                     <img src={serviceQR} alt="" className={styles.serviceQR} />
                    {/*<img src={xcxPng} alt="" className={styles.xcxPng} />*/}
                    <img src={officialQR} alt="" className={styles.officialQR} />
                </div>
            </div>
           
            <div className={styles.bottom_frame}>
                <span><a target="blank" className={styles.beian} href="https://beian.miit.gov.cn">Ⓒ 成都雷石天地电子技术有限公司 蜀ICP备2024089085号-2</a></span>
                <span>
                    <a target="blank" className={styles['beian-record']} href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51015602001010">
                        <img src={policePng} alt="" />
                        川公网安备 51015602001010号
                    </a>
                </span>
            </div>
        </div>
    );
};

export default MobileFooter;