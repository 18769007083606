import React from 'react';
import home_1_1 from '@/assets/images/main/home/1-1.jpg';
import home_2_1 from '@/assets/images/main/home/2-1.jpg';
import home_3_1 from '@/assets/images/main/home/3-1.jpg';
// import home_mallshow_1 from '@/assets/images/main/home/mallshow-1.jpg';
import home_pic_word from '@/assets/images/main/home/pic-word.png';
import home_pic_word2 from '@/assets/images/main/home/pic-word2.png';
import classNames from 'classnames';
import styles from './index.module.scss';

const MobileHomeMain = () => {
    return (
        <main className={styles['lei-content']}>
            <div className={styles['lei-content-word']}>
                <div>
                    <span>家庭互联网娱乐K歌平台倡导者</span>
                </div>
                <p></p>
            </div>
            <div className={classNames(styles['lei-content-pics'], styles['container'])}>
                <div className={styles.row}>
                    <img className={styles.lazyload} src={home_1_1} alt="" />
                </div>
                <div className={styles.row}>
                    <img className={styles.lazyload} src={home_2_1} alt="" />
                </div>
                <div className={styles.row}>
                    <img className={styles.lazyload} src={home_3_1} alt="" />
                </div>
            </div>
            <div className={styles['lei-content-lg']}>
                <div className={styles['lei-content-lg-pic']}>
                    <div>
                        <svg
                            className={styles['fusion-big-half-circle-candy']}
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="100%"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                            style={{fill: '#fff', padding: 0}}
                        >
                            <path d="M0 0 C55 180 100 0 100 0 Z"></path>
                        </svg>
                    </div>
                    <div className={styles['lei-content-lg-pic-word']}>
                        <img
                            className={styles.lazyload}
                            src={home_pic_word}
                            alt=""
                        />
                        <img
                            className={styles.lazyload}
                            src={home_pic_word2}
                            alt=""
                        />
                    </div>
                    <div>
                        <svg
                            className={styles['fusion-big-half-circle-candy']}
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            width="100%"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="none"
                            style={{fill: '#ffffff', padding: 0}}
                        >
                            <path d="M0 100 C40 0 60 0 100 100 Z"></path>
                        </svg>
                    </div>
                </div>
            </div>
            {/* <div className={styles['lei-content-product']}>
                <div className={styles['lei-content-product-title']}>
                    <h2>K歌神器 热销产品</h2>
                </div>
                <div className={styles['lei-content-products']}>
                    <img
                        className={styles.lazyload}
                        src={home_mallshow_1}
                        alt=""
                        style={{width: '100%'}}
                    />
                </div>
            </div> */}
        </main>
    );
};

export default MobileHomeMain;
